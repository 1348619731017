<template>
    <section class="permission">
        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <el-form :inline="true" :model="filters">
                <span>
                    <el-date-picker label="选择月份" v-model="time" type="monthrange" align="right" range-separator="-"
                        start-placeholder="开始月份" end-placeholder="结束月份" :picker-options="pickerOptions" format="yyyy-MM-dd">
                    </el-date-picker>
                    <el-form-item label="平台标识">
                        <el-select class="mr10" v-model="form.identifier" placeholder="请选择平台标识">
                            <el-option label="所有平台" value=""></el-option>
                            <el-option v-for="i in identifierlist" :key="i.identifier"
                                :label="i.name + '-' + i.identifier" :value="i.identifier">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getList(0)" icon="el-icon-search">搜索</el-button>
                    </el-form-item>
                </span>
            </el-form>
        </el-col>

        <!--列表-->
        <el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
            <el-table-column prop="id" label="ID">
            </el-table-column>
            <el-table-column prop="month" label="月份">
            </el-table-column>
            <el-table-column prop="month_active_user" label="活跃用户">
            </el-table-column>
            <el-table-column prop="month_user_add" label="新增用户">
            </el-table-column>
            <el-table-column prop="user_total" label="当月用户总数">
            </el-table-column>
            <el-table-column prop="commit_plan_count" label="提交方案数(包含转单)">
            </el-table-column>
            <el-table-column prop="success_plan_count" label="出票成功数(包含转单)">
            </el-table-column>
            <el-table-column prop="sales_money" label="非转单成功出票销量">
            </el-table-column>
            <el-table-column prop="transfer_money" label="转单成功出票销量">
            </el-table-column>
            <el-table-column prop="online_pay" label="在线支付总额">
            </el-table-column>
            <el-table-column prop="identifier" label="所属平台">
            </el-table-column>
        </el-table>
        <!--工具条-->
        <el-col :span="24" class="toolbar">
            <!--分页-->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
                :page-sizes="pagesizes" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </el-col>

    </section>
</template>

<script>
import { getmoon, identifierlist } from '../../api/api';
export default {
    data() {
        return {
            identifierlist: [],
            time: [],
            form: {
                start_time: '',
                end_time: '',
                identifier: '',
                page: this.page,
                pagesize: this.pagesize
            },
            listData: [],
            //分页
            total: 0,
            page: 1,
            pagesize: 10,//每页条数
            pagesizes: [10, 20, 50, 100],//可选条数
            listLoading: false,
        }
    },
    watch: {
        'time': function (newValue, oldVale) {
            if (this.time) {
                this.form.start_time = formatDate(this.time[0])
                this.form.end_time = formatDate(this.time[1])
            } else {
                this.time = []
            }
        }
    },
    methods: {
        //获取平台标识
        getidentifier() {
            identifierlist().then((res) => {
                if (res.code == 1) {
                    this.identifierlist = res.data;
                } else {
                    this.$notify({
                        title: '错误',
                        message: res.msg,
                        type: 'error'
                    });
                }
            });
        },
        //分页
        handleSizeChange(val) {
            this.pagesize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getList();
        },
        //获取用户列表
        getList(val) {
            if (val == '0') this.page = 1
            this.listLoading = true;
            getmoon(this.form).then((res) => {
                this.listLoading = false;
                if (res.code == 1) {
                    this.total = res.data.count;
                    this.listData = res.data.data;
                    this.listLoading = false;
                } else {
                    this.$notify({
                        title: '错误',
                        message: res.msg,
                        type: 'error'
                    });
                }
            });
        },
    },
    mounted() {
        //获取列表数据
        this.getList();
        this.getidentifier();
    }
}
function formatDate(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从0开始
    return `${year}-${month}`;
}

</script>

<style lang="scss">
@import "../../styles/permission.scss";
</style>